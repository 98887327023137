import React from "react";
import { ENVIRONMENT } from "../scripts/constants";
import { authenticationService } from "../scripts/authentication.service";
import Header from "../header/header";
import {withSnackbar} from "notistack";
import {cf} from "../scripts/custom-fetch";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            env: ENVIRONMENT,
            currentUser: authenticationService.currentUserValue,
            settings: {
                id: 1,
                christmas: 0,
                easter: 0,
                halloween: 0,
                newyear: 0,
                online: 1,
            }
        };
    }

    getSettings = () => {
        const options = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json'},
        };

        cf.get(`${process.env.REACT_APP_API_GET}/settings`, options)
            .then(result => {
                this.setState({
                    settings: result.settings
                })
            })
    }

    componentDidMount() {
        this.getSettings()
    }

    render() {
        const { env, currentUser, settings } = this.state;
        return (
            <>
                <Header />

                {!!settings.christmas && (
                    <div className="main-tree">
                        <div className="home-text">
                            <p>
                                {env === "release" ? "Welcome To Live Social" : "Welcome To Dev Social"}
                            </p>
                            <p>Hi {currentUser.username}</p>
                        </div>
                        <div className="tree-wrap">

                            <div className="star"></div>

                            <ul className="tree">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                            <ul className="tree middle">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                            <ul className="tree bottom">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>

                            <ul className="lightrope">
                                <li style={{top:"40.8%", left:"36%"}}></li>
                                <li style={{top:"41.3%", left:"42%"}}></li>
                                <li style={{top:"40.6%", left:"48%"}}></li>
                                <li style={{top:"38.5%", left:"54%"}}></li>
                                <li style={{top:"55.5%", left:"28%"}}></li>
                                <li style={{top:"56%", left:"34%"}}></li>
                                <li style={{top:"56.2%", left:"40%"}}></li>
                                <li style={{top:"55.5%", left:"46%"}}></li>
                                <li style={{top:"53.9%", left:"52%"}}></li>
                                <li style={{top:"51.3%", left:"58%"}}></li>
                                <li style={{top:"49.1%", left:"62%"}}></li>
                                <li style={{top:"72%", left:"24%"}}></li>
                                <li style={{top:"72.6%", left:"30%"}}></li>
                                <li style={{top:"72.8%", left:"36%"}}></li>
                                <li style={{top:"72.7%", left:"42%"}}></li>
                                <li style={{top:"72%", left:"48%"}}></li>
                                <li style={{top:"70.8%", left:"54%"}}></li>
                                <li style={{top:"68.8%", left:"60%"}}></li>
                                <li style={{top:"65.2%", left:"66%"}}></li>
                            </ul>
                        </div>
                        <div className="ground"></div>
                    </div>
                )}

                {!settings.christmas && (
                    <div className="home-text-normal">
                        <p>
                            {env === "release" ? "Welcome To Live Social" : "Welcome To Dev Social"}
                        </p>
                        <p>Hi {currentUser.username}</p>
                    </div>
                )}

                <div style={{
                    background: "#0000003b",
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "10px",
                    boxShadow: "#00000054 2px 2px 1px 0px",
                }}>
                    <div>Latest Changes</div>
                    <ul style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginLeft: "50px",
                        fontSize: "25px",
                    }}>
                        <li>Translation Engine Coming Soon™</li>
                        <li>Snapchat Reskin :c</li>
                        <li>Form validation everywhere</li>
                    </ul>
                </div>
            </>
        );
    }
}

export default withSnackbar(Home);
