import React from "react";
import {FacebookPost, InstagramPost, PinterestPost, VKPost, FacebookStory, InstagramStory, VKStory, TikTokPost, SnapchatPost, TwitterPost} from "@lumen-developer/lumen-lookalikes/dist/Previews";

class contentPreview extends React.Component {
    render() {
        return (
            <>
                {(this.props.type === "content" || this.props.type === "stim" || this.props.type.includes("watch")) && (
                    <div
                        className={
                            this.props.lookalike.value === "pn"
                                ? this.props.content.promo_desc === "m"
                                    ? "cont-post-edit-pnmax"
                                    : "cont-post-edit-pnstd"
                                : "cont-post-edit"
                        }
                    >
                        {this.props.lookalike.value === "fb" ? (
                            <FacebookPost
                                promoted={this.props.content.promoted || 0}
                                stim_url={this.props.content.src}
                                fb_channel_name={this.props.content.name}
                                fb_profile_src={this.props.content.pic}
                                fb_description={this.props.content.desc}
                                fb_social_settings={this.props.content.social_settings}
                                fb_likes={this.props.content.likes}
                                fb_comments={this.props.content.comments}
                                fb_shares={this.props.content.shares}
                                fb_promoted_domain={this.props.content.promo_link}
                                fb_promoted_description={this.props.content.promo_desc}
                                fb_promoted_description_2={this.props.content.promo_desc_2}
                                fb_promoted_button_text={this.props.content.promo_button}
                                lang_code={"EN"}
                                country_code={"GB"}
                            ></FacebookPost>
                        ) : this.props.lookalike.value === "ig" ? (
                            <InstagramPost
                                promoted={this.props.content.promoted || false}
                                stim_url={this.props.content.src}
                                ig_channel_name={this.props.content.name}
                                ig_profile_src={this.props.content.pic}
                                ig_description={this.props.content.desc}
                                ig_social_settings={this.props.content.social_settings}
                                ig_likes={this.props.content.likes}
                                ig_comments={this.props.content.comments}
                                ig_shares={this.props.content.shares}
                                lang_code={"EN"}
                                country_code={"GB"}
                            ></InstagramPost>
                        ) : this.props.lookalike.value === "pn" ? (
                            <>
                                <PinterestPost
                                    isStim={this.props.content.promoted}
                                    stim_url={this.props.content.src}
                                    pn_stim_title={this.props.content.title}
                                    pn_profile_src={this.props.content.pic}
                                    pn_stim_profile={this.props.content.name}
                                    pn_type={"s"}
                                ></PinterestPost>
                                <div
                                    style={
                                        this.props.content.promo_desc === "m"
                                            ? {}
                                            : {
                                                position: "absolute",
                                                backgroundColor: "gainsboro",
                                                width: "calc(50% - 16px)",
                                                margin: "0px 8px",
                                                borderRadius: "8px",
                                                right: "0px",
                                                top: "8px",
                                                height: "calc(100% - 16px)",
                                            }
                                    }
                                ></div>
                            </>
                        ) : this.props.lookalike.value === "vk" ? (
                            <VKPost
                                stim_url={this.props.content.src}
                                vk_profile_src={this.props.content.pic}
                                vk_stim_profile={this.props.content.name}
                                vk_description={this.props.content.desc}
                                vk_likes={this.props.content.likes}
                                vk_comments={this.props.content.likes}
                                vk_shares={this.props.content.shares}
                                vk_social_settings={this.props.content.social_settings}
                                lang_code={"EN"}
                            ></VKPost>
                        ) : (
                            <TwitterPost
                                promoted={this.props.content.promoted}
                                stim_url={this.props.content.src}
                                fb_channel_name={this.props.content.name}
                                fb_profile_src={this.props.content.pic}
                                fb_description={this.props.content.desc}
                                fb_social_settings={this.props.content.social_settings}
                                fb_likes={this.props.content.likes}
                                fb_comments={this.props.content.comments}
                                fb_shares={this.props.content.shares}
                                fb_views={this.props.content.views}
                                lang_code={"EN"}
                                country_code={"GB"}
                            />
                            )}
                    </div>
                )}
                {(this.props.type.includes("reel") || this.props.type.includes("story")) && (
                    <div className="phone">
                        <div className="box">
                            <div className="tok-frame" style={{ width: "375px", height: "667px" }}>
                                {this.props.type.includes("reel") && (
                                    <TikTokPost
                                        isStim={this.props.content.promoted}
                                        stim_url={this.props.content.src}
                                        tt_profile_name={this.props.content.name}
                                        tt_profile_src={this.props.content.pic}
                                        tt_desc={this.props.content.desc}
                                        tt_song_name={this.props.content.song}
                                        tt_likes={this.props.content.likes}
                                        tt_comments={this.props.content.comments}
                                        tt_shares={this.props.content.shares}
                                        lang_code={"EN"}
                                        country_code={"GB"}
                                    ></TikTokPost>
                                )}
                                {this.props.type.includes("story") && (
                                    <>
                                        {this.props.lookalike.value === "fb" ? (
                                            <FacebookStory
                                                stim_url={this.props.content.src}
                                                fb_profile_src={this.props.content.pic}
                                                fb_channel_name={this.props.content.name}
                                                fb_posted_on={this.props.content.posted_on}
                                                fb_call_to_action={
                                                    this.props.content.cta === "" ? null : this.props.content.cta
                                                }
                                            ></FacebookStory>
                                        ) : this.props.lookalike.value === "ig" ? (
                                            <InstagramStory
                                                stim_url={this.props.content.src}
                                                ig_profile_src={this.props.content.pic}
                                                ig_channel_name={this.props.content.name}
                                                ig_posted_on={this.props.content.posted_on}
                                                ig_call_to_action={
                                                    this.props.content.cta === "" ? null : this.props.content.cta
                                                }
                                            ></InstagramStory>
                                        ) : (
                                            <VKStory
                                                stim_url={this.props.content.src}
                                                vk_profile_src={this.props.content.pic}
                                                vk_channel_name={this.props.content.name}
                                            ></VKStory>
                                        )}
                                    </>
                                )}
                                {this.props.type.includes("snapchat") && (
                                    <SnapchatPost
                                        stim_url={this.props.content.src}
                                        sc_channel_name={this.props.content.name}
                                        sc_description={this.props.content.desc}
                                        sc_pic={this.props.content.pic}
                                        isStim={this.props.content.promoted}
                                        sc_promo_top={this.props.content.promo_top}
                                        sc_promo_text={this.props.content.promo_text}
                                        sc_promo_desc={this.props.content.promo_desc}
                                        sc_cta={this.props.content.cta}
                                    ></SnapchatPost>
                                )}
                            </div>
                            <div className="earSpeaker"></div>
                            <div className="toggleSwitch"></div>
                            <div className="volumeRocker"></div>
                            <div className="powerBtn"></div>
                            <div className="homeBtn"></div>
                        </div>
                    </div>
                )}
                {(this.props.type.includes("video")) && (
                    <div className="phone">
                        Hi
                    </div>
                )}
            </>
        );
    }
}

export default contentPreview;
