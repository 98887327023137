import React from "react";
import {Card, CardContent, FormControlLabel, Switch} from "@mui/material";
import EngineeringIcon from '@mui/icons-material/Engineering';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import AdbIcon from '@mui/icons-material/Adb';
import EggIcon from '@mui/icons-material/Egg';
import CelebrationIcon from '@mui/icons-material/Celebration';

class settings extends React.Component {
    render() {
        return (
            <div className="site-settings">
                <Card sx={{backgroundColor: "#424242", width: "200px"}}>
                    <CardContent style={{backgroundColor: "#515151", color: "#fff"}}>
                        <EngineeringIcon sx={{fontSize: 60 }} />
                    </CardContent>
                    <FormControlLabel
                        className="spacer"
                        control={
                            <Switch
                                checked={!this.props.settings.online}
                                onChange={this.props.handleCheck}
                                name="online"
                                color="primary"
                            />
                        }
                        sx={{padding:"10px", color: "#fff"}}
                        label="Maintenance"
                    />
                </Card>
                <Card sx={{backgroundColor: "#424242", width: "200px"}}>
                    <CardContent style={{backgroundColor: "#515151", color: "#fff"}}>
                        <AcUnitIcon sx={{fontSize: 60 }} />
                    </CardContent>
                    <FormControlLabel
                        className="spacer"
                        control={
                            <Switch
                                checked={this.props.settings.christmas}
                                onChange={this.props.handleCheck}
                                name="christmas"
                                color="primary"
                            />
                        }
                        sx={{padding:"10px", color: "#fff"}}
                        label="Snow"
                    />
                </Card>
                <Card sx={{backgroundColor: "#424242", width: "200px"}}>
                    <CardContent style={{backgroundColor: "#515151", color: "#fff"}}>
                        <AdbIcon sx={{fontSize: 60 }} />
                    </CardContent>
                    <FormControlLabel
                        className="spacer"
                        control={
                            <Switch
                                checked={this.props.settings.halloween}
                                onChange={this.props.handleCheck}
                                name="halloween"
                                color="primary"
                            />
                        }
                        sx={{padding:"10px", color: "#fff"}}
                        label="Spooky"
                    />
                </Card>
                <Card sx={{backgroundColor: "#424242", width: "200px"}}>
                    <CardContent style={{backgroundColor: "#515151", color: "#fff"}}>
                        <EggIcon sx={{fontSize: 60 }} />
                    </CardContent>
                    <FormControlLabel
                        className="spacer"
                        control={
                            <Switch
                                checked={this.props.settings.easter}
                                onChange={this.props.handleCheck}
                                name="easter"
                                color="primary"
                            />
                        }
                        sx={{padding:"10px", color: "#fff"}}
                        label="Egg"
                    />
                </Card>
                <Card sx={{backgroundColor: "#424242", width: "200px"}}>
                    <CardContent style={{backgroundColor: "#515151", color: "#fff"}}>
                        <CelebrationIcon sx={{fontSize: 60 }} />
                    </CardContent>
                    <FormControlLabel
                        className="spacer"
                        control={
                            <Switch
                                checked={this.props.settings.newyear}
                                onChange={this.props.handleCheck}
                                name="newyear"
                                color="primary"
                            />
                        }
                        sx={{padding:"10px", color: "#fff"}}
                        label="New Year"
                    />
                </Card>
            </div>
        );
    }
}

export default settings;