import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "./styles/global.css";

import Home from "./home/Home";
import admin from "./admin/admin";
import tags from "./tags/tags";
import F0F from "./errors/404";

import dupes from "./dupes/dupes";
import manage from "./dupes/components/manage";

import srcErrors from "./srcErrors/srcErrors";
import srcManage from "./srcErrors/components/srcManage";

import personas from "./persona/personas";
import personaEdit from "./persona/personaEdit";
import content from "./content/content";
import contentEdit from "./content/edit";
import contentImport from "./content/pages/import";

import contentProfiles from "./content/pages/profiles";
import contentProfileEdit from "./content/components/profileEdit";
import contentArticles from "./content/pages/postArticles"
import contentArticleEdit from "./content/components/postArticleEdit";
import gmailEdit from "./content/components/gmailEdit";

import music from "./content/music";
import musicEdit from "./content/musicEdit";
import musicStim from "./content/musicStim";
import musicStimEdit from "./content/musicStimEdit";
import playlist from "./content/playlist";
import playlistEdit from "./content/PlaylistEdit";

import article from "./content/article";
import articleEdit from "./content/articleEdit";

import Profile from "./profile/profileClass"

import TranslationEngine from "./translationEngine";

import { history } from "./scripts/history";
import { PrivateRoute } from "./scripts/PrivateRoute";
import { LoginPage } from "./login/login";
import { SnackbarProvider } from "notistack";





import Socials from './socials/Socials'




class App extends Component{
    render() {
        return (
            <Router history={history}>
                <SnackbarProvider maxSnack={3}>
                    <div className="App">
                        <Switch>
                            <PrivateRoute exact path="/" component={Home} />
                            <Route path="/login" component={LoginPage} />
                            <PrivateRoute path="/admin" component={admin} />
                            <PrivateRoute path="/profile" component={Profile} />

                            <Route path="/:socialsitename/view/:id" component={Socials} />

                            <PrivateRoute path="/dupes/manage/:title/:type" component={manage} />
                            <PrivateRoute path="/dupes" component={dupes} />

                            <PrivateRoute path="/srcErrors/manage/:id/:type" component={srcManage} />
                            <PrivateRoute path="/srcErrors" component={srcErrors} />

                            <PrivateRoute path="/personas/edit/:id" component={personaEdit} />
                            <PrivateRoute path="/personas" component={personas} />

                            <PrivateRoute path="/tags" component={tags} />

                            <PrivateRoute path="/content/gmail/edit/:id" component={gmailEdit} />

                            <PrivateRoute path="/content/postArticle/edit/:id" component={contentArticleEdit} />
                            <PrivateRoute path="/content/postArticle" component={contentArticles} />

                            <PrivateRoute path="/content/profile/edit/:id" component={contentProfileEdit} />
                            <PrivateRoute path="/content/profile" component={contentProfiles} />

                            <PrivateRoute path="/content/import" component={contentImport} />
                            <PrivateRoute path="/content/edit/:type/:id" component={contentEdit} />
                            <PrivateRoute path="/content/:type" component={content} />

                            <PrivateRoute path="/language" component={TranslationEngine} />

                            <PrivateRoute path="/musicCont/edit/:id" component={musicEdit} />
                            <PrivateRoute path="/musicCont" component={music} />
                            <PrivateRoute path="/musicStim/edit/:id" component={musicStimEdit} />
                            <PrivateRoute path="/musicStim" component={musicStim} />
                            <PrivateRoute path="/playlist/Edit/:id" component={playlistEdit} />
                            <PrivateRoute path="/playlist" component={playlist} />

                            <PrivateRoute path="/article/edit/:id" component={articleEdit} />
                            <PrivateRoute path="/article" component={article} />

                          
                           
                            

                            <Route component={F0F} />
                        </Switch>
                    </div>
                </SnackbarProvider>
            </Router>
        );
    }
}

export default App;
